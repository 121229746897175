<template>
    <div class="experimentalRatioSum">
        <div class="consume-box">
            <header>
                <ul>
                    <li>
                        <el-select
                            v-model="station_id"
                            placeholder="选择场站"
                            @change="stationChange"
                        >
                            <el-option
                                v-for="(item,index) in stationData"
                                :key="index"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-date-picker
                            v-model="timeArray"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-value="new Date()"
                            @change="timeChange"
                            :clearable="false"
                            :editable="false"
                        >
                        </el-date-picker>
                    </li>
                    <li>
                        <el-input v-model="searchKey" placeholder="请输入搜索内容...">
                            <template slot="append">
                                <div class="search-btn" @click="searchChange()">
                                    <i class="el-icon-search"></i>
                                </div>
                            </template>
                        </el-input>
                    </li>
                    <li>
                        <el-button
                            type="primary"
                            class="export_btn"
                            @click="exportExcelJs1"
                        >
                            <i class="iconfont icondaochu"></i>
                            <span>导出excel</span>
                        </el-button>
                    </li>
                </ul>
            </header>
            <section>
                <div class="table-wrapper table-bg" id="tableWrapper" v-loading="loading">
                    <ux-grid
                        ref="plxTable"
                        :data="tableData"
                        :height="tableHeight"
                        stripe
                        show-overflow
                        width-resize
                        show-header-overflow="ellipsis"
                        :header-row-style="{background: '#edf0f5',color: '#022782'}"
                    >
                        <ux-table-column
                            type="index"
                            title="序号"
                            width="60"
                            align="center"
                        ></ux-table-column>
                        <template
                            v-for="(item,index) in tabelHeader"
                        >
                            <ux-table-column
                                v-if="(item.childs || []).length > 0"
                                :title="item.field_desc"
                                align="center"
                                :key="'tabelHeader'+index"
                            >
                                <template v-if="(item.childs || []).length > 0">
                                    <ux-table-column
                                        v-for="(jtem,jIndex) in item.childs"
                                        :field="jtem.field_name"
                                        :title="jtem.field_desc"
                                        min-width="140"
                                        align="center"
                                        :key="'childHeader'+jIndex"
                                    ></ux-table-column>
                                </template>
                            </ux-table-column>
                            <ux-table-column
                                v-else
                                :field="item.field_name"
                                :title="item.field_desc"
                                align="center"
                                :key="'tabelHeader'+index"
                                min-width="160"
                            >
                            </ux-table-column>
                        </template>
                    </ux-grid>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import { export_json_to_excel } from '@/assets/js/exportExcel';
export default {
    data() {
        return {
            stationData: [{ station_id: '', name: '全部' }], // 场站list
            station_id: '',
            searchKey: '',
            timeArray: ['', ''], // 时间数组
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    },
                }],
            },
            tableHeight: 0,
            tabelHeader: [],
            tableData: [],
            loading: false,
        };
    },
    async created() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.timeArray = [util.timeFormat(start, 'yyyy-MM-dd HH:mm:ss'), util.timeFormat(end, 'yyyy-MM-dd HH:mm:ss')];
        // 获取全部场站
        this.getStationData();
        this.getData();
    },
    mounted() {
        this.getAutoHeight();
    },
    methods: {
        async getData() {
            this.loading = true;
            await this.getTableHeader();
            await this.getTableData();
            this.loading = false;
        },
        // 选择场站触发的事件
        stationChange() {
            this.getData();
        },
        // 获取场站list
        getStationData() {
            this.$axios
                .$get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid')
                + '&roleCode=data_filtering')
                .then(res => {
                    this.stationData.push(...res || []);
                });
        },
        // 时间选择触发的事件
        async timeChange(val) {
            if (val === null) {
                this.timeArray = ['', ''];
            }
            this.getData();
        },
        // 点击搜索按钮触发的事件
        searchChange() {
            this.getData();
        },
        async getTableHeader() {
            const url = '/interfaceApi/production/experimentformula/formula_summary_fields?'
                + `station_id=${this.station_id}&search=${this.searchKey}`
                + `&publish_time_start=${this.timeArray[0]}&publish_time_end=${this.timeArray[1]}`;
            const res = await this.$axios.$get(url);
            this.tabelHeader = res;
        },
        async getTableData() {
            // 大数据下 建议不放入data 内，双向绑定消耗性能
            const url = '/interfaceApi/production/experimentformula/formula_summary_page?'
                + `pageIndex=0&pageSize=999999&station_id=${this.station_id}`
                + `&search=${this.searchKey}&publish_time_start=${this.timeArray[0]}&publish_time_end=${this.timeArray[1]}`;
            const res = await this.$axios.$get(url);
            this.tableData = res.rows;
        },
        getAutoHeight() {
            const el = document.querySelector('#tableWrapper');
            this.$nextTick(() => {
                this.tableHeight = el.offsetHeight - 18;
            });
        },
        getStyle(obj, attr) {
            // 兼容IE浏览器
            const result = obj.currentStyle
                ? obj.currentStyle[attr].replace('px', '')
                : document.defaultView
                    .getComputedStyle(obj, null)[attr].replace('px', '');
            return Number(result);
        },
        // 前端导出表格
        exportExcelJs1() {
            const fieldMap = {};
            const header = [[], []];
            const data = [];
            // 处理表头合并
            const merge = [];
            let colIndex = 0;
            this.tabelHeader.forEach(item => {
                header[0].push(item.field_desc || '');
                if ((item.childs || []).length > 0) {
                    item.childs.forEach((child, childIndex) => {
                        fieldMap[child.field_name] = colIndex;
                        colIndex++;
                        if (childIndex > 0) {
                            header[0].push('');
                        }
                        header[1].push(child.field_desc || '');
                    });
                } else {
                    fieldMap[item.field_name] = colIndex;
                    colIndex++;
                    header[1].push('');
                }
            });
            const row = header[0];
            if (header[1].length > 0) {
                for (let colIndex = 0; colIndex < row.length; colIndex++) {
                    if (row[colIndex]) {
                        if (row[colIndex + 1] || colIndex === row.length - 1) {
                            // 纵向合并 列
                            merge.push({ s: { r: 0, c: colIndex }, e: { r: header.length - 1, c: colIndex } });
                        } else {
                            // 横向合并行
                            // eslint-disable-next-line max-depth
                            for (let endIndex = colIndex; endIndex < row.length; endIndex++) {
                                // eslint-disable-next-line max-depth
                                if (endIndex === row.length - 1 || row[endIndex++]) {
                                    merge.push({ s: { r: 0, c: colIndex }, e: { r: 0, c: endIndex } });
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            this.tableData.forEach(item => {
                const row = [];

                for (const key in fieldMap) {
                    row.push(item[key] || '');
                }
                // 处理空
                data.push(row);
            });
            const merges = merge; // 合并单元格
            const name = '试验配比汇总' + util.currentTimeFormat('yyyy-MM-dd HH_mm_ss');
            export_json_to_excel({
                title: header[0],
                header: header[1],
                data,
                merges,
                filename: name,
                autoWidth: true,
                bookType: 'xlsx',
            });
        },
        handleSizeChange(val) {
            this.page.pagesize = val;
        },
        handleCurrentChange(val) {
            this.page.pageindex = val;
        },
    },
};
</script>

<style lang="stylus" scope>
     .el-progress
        .el-progress-bar
            padding-right 0;
            .el-progress-bar__outer
                height 0.05rem;
        .el-progress__text
            position: absolute;
            top: -0.1rem;
            right: 0.03rem;
    .table-wrapper
        height 100%
        table
            th,td
                height 0.4rem!important;
                text-align center
        .elx-table--empty-content
            width 100%
            height 100%;
            font-size 0
            background url('../../../assets/images/default/list_nothing.png') center no-repeat;
            background-size auto 70%
    .experimentalRatioSum
        .consume-box
            width 100%
            height 100%
            background #ffffff
            display flex
            flex-direction column
            header
                padding 0.1rem 0.1rem 0 0.1rem
                ul
                    display flex
                    height 100%
                    li
                        margin-right 0.1rem
                        .el-date-editor
                            width 4rem !important
                            height 0.36rem;
                            .el-input__icon
                                line-height 0.3rem
                        .el-input-group__append
                            background #1577d2
                            width 0.6rem
                            border none
                            padding 0
                            cursor pointer
                            .search-btn
                                border none
                                background #1577d2
                                width 100%;
                                cursor pointer
                                text-align center
                                i
                                    color #ffffff
                        .export_btn
                            height 0.36rem
                            padding 0 0.14rem
                            background #1577d2
            section
                flex 1
                padding 0.1rem
                overflow hidden
            .page-wrapper
                text-align right
                margin-top 0.1rem
                padding-bottom 0.1rem


</style>
